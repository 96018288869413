import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardHeader,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroup,
} from "reactstrap";
import Select from "react-select";
import InputMask from "react-input-mask";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";
import USA_states from "assets/data/USA_states";
import Breadcrumb from "components/Common/Breadcrumb";

import { BRAND_REGISTRATION } from "config";
import {
  createMyCompany,
  getCompany,
  editMyCompany,
} from "helpers/API_hendlers";
import { modifyCurrentUserSuccess } from "store/actions";

const Brand_Registration = (props) => {
  const { is_admin } = useSelector(
    (state) => ({
      is_admin: state.Login.user.is_admin,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [state, setState] = useState({
    isExistCompanyProfile: false,
    company: {},
    loading: false,
    showAlert: {
      isShow: false,
      title: "Updated successfully",
      message: "",
      type: "success",
      icon: "mdi-information-outline",
    },
  });

  useEffect(() => {
    setState((pS) => ({ ...pS, loading: true }));
    getCompany(props.router.params.id)
      .then((r) => {
        console.log("getCompany", r);
        const brandData = r.company?.a2p?.brand;
        const valuesForSet = { ...val.values };
        if (brandData) {
          for (const key in brandData) {
            valuesForSet[key] = brandData[key];
          }
        }
        val.setValues(valuesForSet);

        const valuesSIDs_ForSet = { ...val_SIDs.values };
        if (r.company) {
          for (const key in valuesSIDs_ForSet) {
            valuesSIDs_ForSet[key] = r.company[key];
          }
        }

        val_SIDs.setValues(valuesSIDs_ForSet);

        setState((pS) => {
          const newState = {
            ...pS,
            company: r.company,
            isExistCompanyProfile: true,
            loading: false,
          };
          if (r?.company?.applied === 1) {
            newState.showAlert = {
              isShow: true,
              message:
                "The A2P profile form is being reviewed and you cannot change any details until the review is complete",
              type: "success",
              icon: "mdi-information-outline",
            };
          }

          return newState;
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.msg === "Cannot find company") {
          setState((pS) => ({
            ...pS,
            isExistCompanyProfile: false,
            loading: false,
          }));
        }
      });
  }, []);

  const val_SIDs = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand_sid: "",
      campaign_sid: "",
      m_service_sid: "",
    },
    validationSchema: Yup.object({
      brand_sid: Yup.string().required("Required"),
      campaign_sid: Yup.string().required("Required"),
      m_service_sid: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_val_SIDs", values);

      editMyCompany(values, props.router.params.id)
        .then((r) => {
          console.log("EDITED brand SIDs", r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_business_entity: "Yes_business_entity",
      hasTaxID: "Yes_has_tax_id",
      would_like_register_secondary: "Yes_secondary",
      legal_business_name: "",
      business_identity: "direct_customer",
      business_type: "",
      business_registration_identifier: "EIN",
      business_registration_number: "",
      business_regions_of_operation: "USA_AND_CANADA",
      website_url: "",

      iso_country: "USA",
      street: "",
      street_secondary: "",
      city: "",
      region: "",
      postal_code: "",

      // email_address: "hello@coolcloud.com",
      // autorised_first_name: "",
      // autorised_last_name: "",
      // autorised_email: "",
      // autorised_business_title: "",
      // autorised_country_code: "USA_AND_CANADA",
      // autorised_phone_number: "",
      // autorised_job_position: "",
      // add_another_authorized: "No_add_another_authorized",

      company_type: "Private",
      company_stock_ticker: "",
      stock_exchange: "",
      are_527_politic: "",
      brand_type: "low_value",

      // is_confirm_contacted_twilio: false,
      is_info_accurate: false,
      is_agree_info_agree_fee: false,
    },
    validationSchema: Yup.object({
      // is_business_entity: Yup.string().required("Required"),
      // hasTaxID: Yup.string().required("Required"),
      // would_like_register_secondary: Yup.string().required("Required"),
      // legal_business_name: Yup.string().required("Required"),
      // business_identity: Yup.string().required("Required"),
      // business_type: Yup.object().required("Required"),
      // business_registration_identifier: Yup.object().required("Required"),
      // business_registration_number: Yup.string().required("Required"),
      // business_regions_of_operation: Yup.string().required("Required"),
      // website_url: Yup.string().required("Required"),
      // iso_country: Yup.string().required("Required"),
      // street: Yup.string().required("Required"),
      // city: Yup.string().required("Required"),
      // region: Yup.object().required("Required"),
      // postal_code: Yup.string().required("Required"),
      // company_type: Yup.string().required("Required"),
      // email_address: Yup.string().required("Required"),
      // autorised_first_name: Yup.string().required("Required"),
      // autorised_last_name: Yup.string().required("Required"),
      // autorised_email: Yup.string()
      //   .email("Invalid email address")
      //   .required("Required"),
      // autorised_business_title: Yup.string().required("Required"),
      // autorised_country_code: Yup.string().required("Required"),
      // autorised_phone_number: Yup.string().required("Required"),
      // autorised_job_position: Yup.object().required("Required"),
      // add_another_authorized: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit_validation_business_registration", values);
      try {
        const payload = {
          a2p: { ...state.company.a2p, brand: { ...values } }, // if set a2p: null/{}/[]/""/0, тоді a2p перезапишеться на {}
        };

        let result = null;

        if (state.isExistCompanyProfile) {
          editMyCompany(payload, props.router.params.id)
            .then((r) => {
              result = r;
              console.log("EDITED brand", r);
            })
            .catch((e) => {
              throw e;
            });
        } else {
          const payload = {
            title: values.legal_business_name || "default name",
            margin: values.margin || 0,
            factor: values.factor || 0,
            a2p: { brand: { ...values } },
          };

          createMyCompany(payload)
            .then((r) => {
              result = r;
              console.log("CREATED brand", r);
            })
            .catch((e) => {
              throw e;
            });
        }

        dispatch(
          modifyCurrentUserSuccess({
            m_service_sid: result?.company?.m_service_sid,
          })
        );
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  });

  const submitToReview = () => {
    val
      .validateForm()
      .then((r) => {
        console.log(r);

        if (JSON.stringify(r) !== "{}") {
          let MS_ER = "";
          for (const key in r) {
            MS_ER += `${key}: "${r[key]}",  `;
          }
          setState((pS) => ({
            ...pS,
            showAlert: {
              isShow: true,
              title: "Some fields are not valide",
              message: MS_ER,
              type: "danger",
              icon: "mdi-block-helper",
            },
          }));
        } else {
          const vv = val.values;
          console.log(vv, state.company);

          if (!vv.is_info_accurate) {
            setState((pS) => ({
              ...pS,
              showAlert: {
                isShow: true,
                title: "Notice",
                message: "You need to agree about accurate information",
                type: "warning",
                icon: "mdi-information-outline",
              },
            }));
            return;
          }

          setState(
            (pS) => ({
              ...pS,
              showAlert: {
                isShow: true,
                title: "Success",
                message: "Form is valid, and has been submitted",
                type: "success",
                icon: "mdi-information-outline",
              },
            }),
            val.handleSubmit()
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitToReview_SIDs = () => {
    val_SIDs
      .validateForm()
      .then((r) => {
        console.log("VALIDATION SIDs", r);

        if (JSON.stringify(r) !== "{}") {
          let MS_ER = "";
          for (const key in r) {
            MS_ER += `${key}: "${r[key]}",  `;
          }
          setState((pS) => ({
            ...pS,
            showAlert: {
              isShow: true,
              title: "Some fields are not valide",
              message: MS_ER,
              type: "danger",
              icon: "mdi-block-helper",
            },
          }));
        } else {
          setState(
            (pS) => ({
              ...pS,
              showAlert: {
                isShow: true,
                title: "Success",
                message: "New SIDs have been submitted",
                type: "success",
                icon: "mdi-information-outline",
              },
            }),
            val_SIDs.handleSubmit()
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      className={`page-content ${
        state.company.applied === 1 ? "overlay_block" : ""
      }`}
    >
      <Container fluid>
        <Breadcrumb
          title="Brand Registration"
          breadcrumbItems={props?.router?.location?.pathname}
        />
        {state.showAlert.isShow && (
          <Alert
            style={{ zIndex: 1000, display: "flex", position: "fixed" }}
            isOpen={state.showAlert.isShow}
            color={state.showAlert.type}
            className="alert-dismissible fade show"
            role="alert"
            toggle={() => {
              setState((pS) => ({
                ...pS,
                showAlert: {
                  ...state.showAlert,
                  isShow: !state.showAlert.isShow,
                },
              }));

              if (
                state.showAlert.message ===
                "Form is valid, and has been submitted"
              ) {
                props.router.navigate(-1);
              }
            }}
          >
            <i className={`mdi ${state.showAlert.icon} me-2 font-size-20`}></i>
            <div>
              <h5>{state.showAlert.title}</h5>{" "}
              {state.showAlert.message && (
                <p className="mb-0">{state.showAlert.message}</p>
              )}
            </div>
          </Alert>
        )}
        {is_admin === 2 && (
          <Card>
            <CardBody>
              <Label className="form-label">
                Super Admin Brand's adjustment fileds
              </Label>

              <div className="mb-3 form-group">
                <Label className="form-label">
                  <span className="red_star">*</span> Brand SID
                </Label>
                <Input
                  name="brand_sid"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val_SIDs.handleChange}
                  onBlur={val_SIDs.handleBlur}
                  value={val_SIDs.values.brand_sid || ""}
                  invalid={
                    val_SIDs.touched.brand_sid && val_SIDs.errors.brand_sid
                      ? true
                      : false
                  }
                />
                {val_SIDs.touched.brand_sid && val_SIDs.errors.brand_sid ? (
                  <FormFeedback type="invalid">
                    {val_SIDs.errors.brand_sid}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3 form-group">
                <Label className="form-label">
                  <span className="red_star">*</span> Campaign SID
                </Label>
                <Input
                  name="campaign_sid"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val_SIDs.handleChange}
                  onBlur={val_SIDs.handleBlur}
                  value={val_SIDs.values.campaign_sid || ""}
                  invalid={
                    val_SIDs.touched.campaign_sid &&
                    val_SIDs.errors.campaign_sid
                      ? true
                      : false
                  }
                />
                {val_SIDs.touched.campaign_sid &&
                val_SIDs.errors.campaign_sid ? (
                  <FormFeedback type="invalid">
                    {val_SIDs.errors.campaign_sid}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3 form-group">
                <Label className="form-label">
                  <span className="red_star">*</span> Connected Messaging
                  Service SID
                </Label>
                <Input
                  name="m_service_sid"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val_SIDs.handleChange}
                  onBlur={val_SIDs.handleBlur}
                  value={val_SIDs.values.m_service_sid || ""}
                  invalid={
                    val_SIDs.touched.m_service_sid &&
                    val_SIDs.errors.m_service_sid
                      ? true
                      : false
                  }
                />
                {val_SIDs.touched.m_service_sid &&
                val_SIDs.errors.m_service_sid ? (
                  <FormFeedback type="invalid">
                    {val_SIDs.errors.m_service_sid}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="input-group-prepend">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitToReview_SIDs}
                >
                  Update Brand SIDs
                </button>
              </div>
            </CardBody>
          </Card>
        )}

        <Card>
          <CardBody>
            <h4 className="card-title">Determine profile needs</h4>

            <Row className="mb-3">
              <Label className="form-label">
                <span className="red_star">*</span> Is the business entity
                you’re registering located in the US and/or Canada?
              </Label>
              <div className="">
                {BRAND_REGISTRATION.is_business_entity.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_business_entity"
                      disabled
                      checked={val.values.is_business_entity === bi.value}
                      id={bi.value}
                      onChange={(s) =>
                        val.setFieldValue("is_business_entity", bi.value)
                      }
                    />
                    <label className="form-check-label" htmlFor={bi.value}>
                      {bi.label}
                    </label>
                  </div>
                ))}
              </div>

              <Alert
                style={{ display: "flex" }}
                isOpen={true}
                color={"info"}
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className={`mdi mdi-information me-2 font-size-20`}></i>
                <div>
                  <h5>Notice for US or Canadian customers with Tax ID!</h5>
                  <p>
                    US or Canadian customers who have a Tax ID need to register
                    for a Low Volume Standard or Standard brand to send messages
                    to the US.
                  </p>
                  <p>
                    US or Canadian customers who do not have a tax ID, need to
                    register for a Sole Proprietor brand to send messages to the
                    US.
                  </p>
                </div>
              </Alert>
              <Label className="form-label">
                <span className="red_star">*</span> Does the business you’re
                registering have a tax ID (Ex. US EIN)?
              </Label>

              <div className="">
                {BRAND_REGISTRATION.hasTaxID.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasTaxID"
                      disabled
                      checked={val.values.hasTaxID === bi.value}
                      id={bi.value}
                      onChange={(s) => val.setFieldValue("hasTaxID", bi.value)}
                    />
                    <label className="form-check-label" htmlFor={bi.value}>
                      {bi.label}
                    </label>
                  </div>
                ))}
              </div>
              {/* 
              <Label className="form-label">
                <span className="red_star">*</span>Would you like to register a
                Secondary customer profile?
              </Label>

              <div className="">
                {BRAND_REGISTRATION.would_like_register_secondary.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="would_like_register_secondary"
                      disabled
                      checked={
                        val.values.would_like_register_secondary === bi.value
                      }
                      id={bi.value}
                      onChange={(s) =>
                        val.setFieldValue(
                          "would_like_register_secondary",
                          bi.value
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor={bi.value}>
                      {bi.label}
                    </label>
                  </div>
                ))}
              </div> */}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title">Create Customer Profile</h4>
            <Row className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Legal business name
              </Label>
              <Input
                name="legal_business_name"
                className="form-control mb-3"
                placeholder="*"
                type="text"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={val.values.legal_business_name || ""}
                invalid={
                  val.touched.legal_business_name &&
                  val.errors.legal_business_name
                    ? true
                    : false
                }
              />
              {val.touched.legal_business_name &&
              val.errors.legal_business_name ? (
                <FormFeedback type="invalid">
                  {val.errors.legal_business_name}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Label className="form-label">
                <span className="red_star">*</span> Business Identity
              </Label>
              <div className="">
                {BRAND_REGISTRATION.business_identities.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="business_identity"
                      checked={val.values.business_identity === bi.value}
                      disabled
                      id={bi.value}
                      onChange={(s) =>
                        val.setFieldValue("business_identity", bi.value)
                      }
                    />
                    <label className="form-check-label" htmlFor={bi.value}>
                      {bi.label}
                    </label>
                  </div>
                ))}
              </div>
            </Row>
            <Row className="mb-3 form-group">
              <Col>
                <Label className="form-label">
                  <span className="red_star">*</span> Business Type
                </Label>
                <Select
                  options={BRAND_REGISTRATION.business_type}
                  onChange={(s) => val.setFieldValue("business_type", s)}
                  value={val.values.business_type || ""}
                  className="select2-selection"
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </Col>
              <Col className="form-group">
                <Label className="form-label">
                  <span className="red_star">*</span> Business Industry
                </Label>
                <Select
                  options={BRAND_REGISTRATION.business_industry}
                  onChange={(s) => val.setFieldValue("business_industry", s)}
                  value={val.values.business_industry}
                  className="select2-selection"
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3 form-group">
              <Col>
                <Label className="form-label">
                  <span className="red_star">*</span> Business Registration ID
                  Type
                </Label>
                <Select
                  options={BRAND_REGISTRATION.business_registration_identifier}
                  onChange={(s) =>
                    val.setFieldValue("business_registration_identifier", s)
                  }
                  value={val.values.business_registration_identifier || ""}
                  className="select2-selection"
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </Col>
              <Col className="form-group">
                <Label className="form-label">
                  <span className="red_star">*</span> Business Registration
                  Number
                </Label>

                <InputMask
                  disabled={!val.values.business_registration_identifier?.mask}
                  mask={
                    val.values.business_registration_identifier?.mask ||
                    "99-9999999"
                  }
                  name="business_registration_number"
                  value={val.values.business_registration_number || ""}
                  className="form-control input-color"
                  onChange={val.handleChange}
                ></InputMask>
                <Label className="form-label">
                  Enter the EIN Tax ID as it appears in the EIN listing
                </Label>
              </Col>
            </Row>
            <Row className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Business Regions of
                Operations
              </Label>
              <div className="">
                {BRAND_REGISTRATION.business_regions_of_operation.map((d) => (
                  <div key={d.label} className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="business_regions_of_operation"
                      checked={
                        val.values.business_regions_of_operation === d.value
                      }
                      id={d.value}
                      onChange={(s) =>
                        val.setFieldValue(
                          "business_regions_of_operation",
                          d.value
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor={d.value}>
                      {d.label}
                    </label>
                  </div>
                ))}
              </div>
            </Row>
            <Row className="mb-3 form-group">
              <Col className="form-group" xs={7}>
                <Label className="form-label">
                  <span className="red_star">*</span> Website Url
                </Label>
                <Input
                  name="website_url"
                  // value={name}
                  className="form-control"
                  placeholder="https://"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.website_url || ""}
                  invalid={
                    val.touched.website_url && val.errors.website_url
                      ? true
                      : false
                  }
                />
                {val.touched.website_url && val.errors.website_url ? (
                  <FormFeedback type="invalid">
                    {val.errors.website_url}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title">Business Address</h4>
            <Row className="mb-3 form-group">
              <Col xs={12} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Country
                </Label>
                <Input
                  name="iso_country"
                  disabled
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.iso_country || ""}
                />
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Street Address
                </Label>
                <Input
                  name="street"
                  autoComplete=""
                  type="address"
                  placeholder="*"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.street || ""}
                  invalid={
                    val.touched.street && val.errors.street ? true : false
                  }
                />
                {val.touched.street && val.errors.street ? (
                  <FormFeedback type="invalid">
                    {val.errors.street}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  Enter the first line of the business address as it appears in
                  the EIN listing
                </p>
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star"></span> Street Address Line 2
                </Label>
                <Input
                  name="street_secondary"
                  autoComplete=""
                  type="address"
                  placeholder="*"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.street_secondary || ""}
                  invalid={
                    val.touched.street_secondary && val.errors.street_secondary
                      ? true
                      : false
                  }
                />
                {val.touched.street_secondary && val.errors.street_secondary ? (
                  <FormFeedback type="invalid">
                    {val.errors.street_secondary}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  If application, enter the second line of the business address
                  as it appears in the EIN listing
                </p>
              </Col>
              <Col xs={12} md={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> City
                </Label>
                <Input
                  name="city"
                  autoComplete=""
                  type="text"
                  placeholder="City"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.city || ""}
                  invalid={val.touched.city && val.errors.city ? true : false}
                />
                {val.touched.city && val.errors.city ? (
                  <FormFeedback type="invalid">{val.errors.city}</FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  Enter the city name as it appears in the EIN listing.
                </p>
              </Col>
              <Col xs={6} md={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> State
                </Label>
                <Select
                  onChange={(s) => val.setFieldValue("region", s)}
                  onBlur={val.handleBlur}
                  value={val.values.region || ""}
                  options={USA_states}
                  className="select2-selection"
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
                <p className="m-0 font-size-12">
                  Enter the state/province/region as it appears in the EIN
                  listing
                </p>
              </Col>
              <Col xs={6} md={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Postal/Zip Code
                </Label>
                <Input
                  name="postal_code"
                  autoComplete=""
                  maxLength={5}
                  type="number"
                  placeholder="Zip"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={val.values.postal_code || ""}
                  invalid={
                    val.touched.postal_code && val.errors.postal_code
                      ? true
                      : false
                  }
                />
                {val.touched.postal_code && val.errors.postal_code ? (
                  <FormFeedback type="invalid">
                    {val.errors.postal_code}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  Enter the postal code as it appears in the EIN listing
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* <Card>
          <CardBody>
            <h4 className="card-title">Notification Settings</h4>
            <Row className="mb-3 form-group">
              <Alert
                style={{ display: "flex" }}
                isOpen={true}
                color={"info"}
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className={`mdi mdi-information me-2 font-size-20`}></i>
                <div>
                  <h5>
                    To notify multiple recipients, consider using a distribution
                    email address.
                  </h5>
                  <p>
                    We will use the provided email address to notify recipients
                    about the status of this Customer Profile. This email
                    address will not be subscribed to any marketing emails.
                  </p>
                </div>
              </Alert>
              <Label className="form-label">
                <span className="red_star">*</span> Email Address
              </Label>
              <Input
                name="email_address"
                className="form-control"
                disabled
                placeholder="*"
                type="text"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={
                  val.values.email_address || ""
                }
                invalid={
                  val.touched.email_address &&
                  val.errors.email_address
                    ? true
                    : false
                }
              />
              {val.touched.email_address &&
              val.errors.email_address ? (
                <FormFeedback type="invalid">
                  {val.errors.email_address}
                </FormFeedback>
              ) : null}
            </Row>
          </CardBody>
        </Card> */}

        {/* <Card>
          <CardBody>
            <h4 className="card-title">Authorized Representatives</h4>
            <p className="m-0 font-size-12">
              Please provide information about the people who can be contacted
              about the information provided for this customer profile. They’ll
              only be contacted in case there are any issues with the submitted
              information.
            </p>
            <Row className="mb-2">
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> First Name
                </Label>
                <Input
                  name="autorised_first_name"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={
                    val.values
                      .autorised_first_name || ""
                  }
                  invalid={
                    val.touched
                      .autorised_first_name &&
                    val.errors.autorised_first_name
                      ? true
                      : false
                  }
                />
                {val.touched
                  .autorised_first_name &&
                val.errors.autorised_first_name ? (
                  <FormFeedback type="invalid">
                    {
                      val.errors
                        .autorised_first_name
                    }
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Last Name
                </Label>
                <Input
                  name="autorised_last_name"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={
                    val.values
                      .autorised_last_name || ""
                  }
                  invalid={
                    val.touched
                      .autorised_last_name &&
                    val.errors.autorised_last_name
                      ? true
                      : false
                  }
                />
                {val.touched.autorised_last_name &&
                val.errors.autorised_last_name ? (
                  <FormFeedback type="invalid">
                    {
                      val.errors
                        .autorised_last_name
                    }
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Email
                </Label>
                <Input
                  name="autorised_email"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={
                    val.values.autorised_email ||
                    ""
                  }
                  invalid={
                    val.touched.autorised_email &&
                    val.errors.autorised_email
                      ? true
                      : false
                  }
                />
                {val.touched.autorised_email &&
                val.errors.autorised_email ? (
                  <FormFeedback type="invalid">
                    {val.errors.autorised_email}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Business Title
                </Label>
                <Input
                  name="autorised_business_title"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={
                    val.values
                      .autorised_business_title || ""
                  }
                  invalid={
                    val.touched
                      .autorised_business_title &&
                    val.errors
                      .autorised_business_title
                      ? true
                      : false
                  }
                />
                {val.touched
                  .autorised_business_title &&
                val.errors
                  .autorised_business_title ? (
                  <FormFeedback type="invalid">
                    {
                      val.errors
                        .autorised_business_title
                    }
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Country Code
              </Label>
              <div className="">
                {BRAND_REGISTRATION.business_regions_of_operation.map((d) => (
                  <div key={d.label} className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="autorised_country_code"
                      checked={
                        val.values
                          .autorised_country_code === d.value
                      }
                      id={d.value}
                      onChange={(s) =>
                        val.setFieldValue(
                          "autorised_country_code",
                          d.value
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor={d.value}>
                      {d.label}
                    </label>
                  </div>
                ))}
              </div>
            </Row>
            <Row className="mb-3 form-group">
              <Col xs={6}>
                <Label className="form-label">
                  <span className="red_star">*</span> Job Position
                </Label>
                <Select
                  name="autorised_job_position"
                  options={BRAND_REGISTRATION.job_position}
                  className="select2-selection"
                  onChange={(s) =>
                    val.setFieldValue(
                      "autorised_job_position",
                      s
                    )
                  }
                  value={
                    val.values
                      .autorised_job_position || ""
                  }
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </Col>
              <Col className="form-group mb-3" xs={6}>
                <Label className="form-label">
                  <span className="red_star">*</span> Phone Number
                </Label>
                <InputMask
                  mask="+1 (999) 999-9999"
                  name="autorised_phone_number"
                  // value={name}
                  className="form-control"
                  placeholder=""
                  type="text"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={
                    val.values
                      .autorised_phone_number || ""
                  }
                />
              </Col>
            </Row>

            <h4 className="card-title">
              Do you want to add another authorized representative? (Optional)
            </h4>
            <p className="m-0 font-size-12">
              This authorized representative will potentially be contacted if
              the first one does not respond.
            </p>
            <div className="">
              {BRAND_REGISTRATION.add_another_authorized.map((bi) => (
                <div key={bi.value} className="form-check mb-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="add_another_authorized"
                    disabled
                    checked={
                      val.values
                        .add_another_authorized === bi.value
                    }
                    id={bi.value}
                    onChange={(s) =>
                      val.setFieldValue(
                        "add_another_authorized",
                        bi.value
                      )
                    }
                  />
                  <label className="form-check-label" htmlFor={bi.value}>
                    {bi.label}
                  </label>
                </div>
              ))}
            </div>
          </CardBody>
        </Card> */}

        <Card>
          <CardBody>
            <h4 className="card-title">Submit Brand</h4>
            <p className="m-0 font-size-12">
              We’ll register the brand on your behalf with The Campaign
              Registry(TCR) , the third party that handles the process for all
              carriers. There is a one-time fee of $4 to register a brand. Once
              the Brand and Campaign information is approved, your business will
              be able to send up to 3,000 segments per day.
            </p>

            <Row className="mb-3 mt-3 form-group">
              <Label className="form-label mb-0">
                <span className="red_star">*</span> Company Type
              </Label>
              <p className="font-size-11">
                Non-US nonprofits and governments should register as a private
                or public company.
              </p>
              <div className="d-flex flex-wrap gap-3">
                {BRAND_REGISTRATION.company_types.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="company_type"
                      checked={val.values.company_type === bi.value}
                      id={bi.value}
                      onChange={(s) =>
                        val.setFieldValue("company_type", bi.value)
                      }
                    />
                    <label className="form-check-label" htmlFor={bi.value}>
                      {bi.label}
                    </label>
                  </div>
                ))}
              </div>
            </Row>

            {val.values.company_type === "Public" && (
              <Row className="mb-3 form-group">
                <Col>
                  <Label className="form-label">
                    <span className="red_star">*</span> Company Stock Ticker
                    Symbol
                  </Label>
                  <Input
                    name="company_stock_ticker"
                    className="form-control mb-3"
                    placeholder="*"
                    type="text"
                    onChange={val.handleChange}
                    onBlur={val.handleBlur}
                    value={val.values.company_stock_ticker || ""}
                    invalid={
                      val.touched.company_stock_ticker &&
                      val.errors.company_stock_ticker
                        ? true
                        : false
                    }
                  />
                  {val.touched.company_stock_ticker &&
                  val.errors.company_stock_ticker ? (
                    <FormFeedback type="invalid">
                      {val.errors.company_stock_ticker}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <Label className="form-label">
                    <span className="red_star">*</span> Stock exchange
                  </Label>
                  <Select
                    options={BRAND_REGISTRATION.stock_exchange}
                    onChange={(s) => val.setFieldValue("stock_exchange", s)}
                    value={val.values.stock_exchange || ""}
                    className="select2-selection"
                    styles={{
                      menuList: (style) => ({
                        ...style,
                        backgroundColor: "white",
                      }),
                    }}
                  />
                </Col>
              </Row>
            )}

            {val.values.company_type === "us_non_profit" && (
              <Row className="mb-3">
                <Label className="form-label">
                  <span className="red_star">*</span> Are you a 527 Political
                  Organization
                </Label>
                <div className="d-flex gap-4">
                  {BRAND_REGISTRATION.are_527_politic.map((bi) => (
                    <div key={bi.value} className="form-check mb-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="are_527_politic"
                        checked={val.values.are_527_politic === bi.value}
                        id={bi.value}
                        onChange={(s) =>
                          val.setFieldValue("are_527_politic", bi.value)
                        }
                      />
                      <label
                        className="form-check-label fw-bolder"
                        htmlFor={bi.value}
                      >
                        {bi.label}
                      </label>
                    </div>
                  ))}
                </div>
                <Alert
                  style={{ display: "flex" }}
                  isOpen={true}
                  color={"info"}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  <i className={`mdi mdi-information me-2 font-size-20`}></i>
                  <div>
                    <p>
                      Nonprofits and government entities must register as
                      Standard Brands. This ensures additional vetting for these
                      organizations to unlock reduced carrier fees and/or
                      increased messaging throughput. Read more
                    </p>
                  </div>
                </Alert>
              </Row>
            )}

            {val.values.company_type === "us_government" && (
              <Row className="mb-3">
                <Alert
                  style={{ display: "flex" }}
                  isOpen={true}
                  color={"info"}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  <i className={`mdi mdi-information me-2 font-size-20`}></i>
                  <div>
                    <p>
                      Nonprofits and government entities must register as
                      Standard Brands. This ensures additional vetting for these
                      organizations to unlock reduced carrier fees and/or
                      increased messaging throughput. Read more
                    </p>
                  </div>
                </Alert>
              </Row>
            )}

            {/* <Row className="mb-3">
              <Label className="form-label">
                <span className="red_star">*</span> What type of brand do you
                need?
              </Label>
              <div className="">
                {BRAND_REGISTRATION.brand_types.map((bi) => (
                  <div key={bi.value} className="form-check mb-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="brand_type"
                      checked={val.values.brand_type === bi.value}
                      id={bi.value}
                      onChange={(s) =>
                        val.setFieldValue("brand_type", bi.value)
                      }
                    />
                    <label
                      className="form-check-label fw-bolder"
                      htmlFor={bi.value}
                    >
                      {bi.label} <span className="fw-light">{bi.labelAdd}</span>
                    </label>
                    <p className="font-size-11">{bi.description}</p>
                  </div>
                ))}
              </div>
            </Row> */}
            {/* <Button type="button" onClick={registerBrand} color="primary">
              Register
            </Button> */}
          </CardBody>
        </Card>

        {/* <Card className="bg-secondary-subtle">
          <CardBody>
            <div className="form-check form-check-primary p-4">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheckcolor1"
                checked={val.values.is_agree_info_agree_fee}
                onChange={(s) =>
                  val.setFieldValue(
                    "is_agree_info_agree_fee",
                    !val.values.is_agree_info_agree_fee
                  )
                }
              />

              <label className="form-check-label" htmlFor="customCheckcolor1">
                I agree the above information is correct. I agree to a one-time
                $44 A2P brand registration fee.
                {"  "}
                <a href="https://support.twilio.com/hc/en-us/articles/4410588996123-U-S-Carrier-Penalties-for-Non-Compliant-Messaging">
                  Learn more about the registration fee
                </a>
              </label>
            </div>
          </CardBody>
        </Card> */}

        <Card className="bg-secondary-subtle">
          <CardBody>
            <div className="form-check form-check-primary p-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheckcolor1"
                checked={val.values.is_info_accurate}
                onChange={(s) =>
                  val.setFieldValue(
                    "is_info_accurate",
                    !val.values.is_info_accurate
                  )
                }
              />

              <label className="form-check-label" htmlFor="customCheckcolor1">
                I declare that the information provided is accurate. I
                acknowledge that Twilio will be processing the information
                provided for the purposes of identity verification, and that
                Twilio reserves the right to retain the Customer Profile
                information after account closure in the case of a traceback
                from a regulatory authority or equivalent.
                <p className="mb-0 mt-2">
                  Twilio will process your personal data according to the {"  "}
                  <a href="https://www.twilio.com/en-us/legal/privacy">
                    Twilio Privacy Statement
                  </a>
                </p>
              </label>
            </div>
          </CardBody>
        </Card>

        {/* <Card className="bg-secondary-subtle">
          <CardBody>
            <div className="form-check form-check-primary p-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheckcolor1"
                checked={
                  val.values
                    .is_confirm_contacted_twilio
                }
                onChange={(s) =>
                  val.setFieldValue(
                    "is_confirm_contacted_twilio",
                    !val.values
                      .is_confirm_contacted_twilio
                  )
                }
              />

              <label className="form-check-label" htmlFor="customCheckcolor1">
                I confirm that my nominated authorized representatives agree to
                be contacted by Twilio.
              </label>
            </div>
          </CardBody>
        </Card> */}

        <Card>
          <CardBody>
            <Button
              type="button"
              onClick={submitToReview}
              color="primary"
              style={{ marginRight: 20 }}
            >
              Submit for review
            </Button>
            <Button
              type="button"
              onClick={() => {
                props.router.navigate(-1);
              }}
              outline
            >
              Cancel
            </Button>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default withRouter(Brand_Registration);
