import React, { useEffect, useState } from "react";
import {
    Badge,
    Button,
    Container,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import TagsInput from 'react-tagsinput';

import 'react-tagsinput/react-tagsinput.css';
// import './react-tagsinput.css';
import CategorySelector from "./CategorySelector";

const TrainingData = ({ index, id, client_file_name, parent_id, tags: _tags, question: _question, answer: _answer, created, approved, onApprove, onSave, onDelete, onVariations, globalTags }) => {
    const [question, setQuestion] = useState(_question);
    const [answer, setAnswer] = useState(_answer);
    const [tags, setTags] = useState(_tags || []);
    const [allCategories, setAllCategories] = useState(globalTags || {});
    // const [newPrimaryTag, setNewPrimaryTag] = useState();
    // const [newSecondaryTag, setNewSecondaryTag] = useState();

    // const onNewTag = () => {
    //     if (newPrimaryTag && newSecondaryTag) {
    //         setTags([...tags, `${newPrimaryTag}:${newSecondaryTag}`]);
    //         setNewPrimaryTag();
    //         setNewSecondaryTag();
    //     }
    // };
    const [selectedPairs, setSelectedPairs] = useState(tags.map(tag => {
        const [category, subcategory] = tag.split(":");
        return { category, ...(subcategory ? { subcategory } : {}) };
    }));

    useEffect(() => {
        setTags(selectedPairs.map(pair => `${pair.category}${pair.subcategory ? `:${pair.subcategory}` : ""}`));
    }, [selectedPairs]);

    const isChanged = (field = null) => {
        if (field === "tags") {
            return (tags || []).sort().join(",") !== (_tags || []).sort().join(",");
        } else if (field === "question") {
            return question !== _question;
        } else if (field === "answer") {
            return answer !== _answer;
        } else {
            return isChanged("tags") || isChanged("question") || isChanged("answer");
        }
    };

    return (
        <tr style={parent_id ? {} : { fontWeight: "bold" }}>
            <td>
                <small>
                    <div>{id}</div>
                    {id ?
                        (isChanged() ?
                            <Button size="sm" className="mx-1 my-1" color="primary" onClick={() => onSave(id, { question, answer, tags })}> Save </Button> :
                            <Button size="sm" className="mx-1 my-1" disabled>Saved</Button>
                        ) : (isChanged() ?
                            <Button size="sm" className="mx-1 my-1" color="primary" onClick={async () => {
                                await onSave(null, { question, answer, tags });
                            }}> Save </Button> :
                            <Button size="sm" className="mx-1 my-1" disabled>Unsaved</Button>
                        )}
                </small>
            </td>
            <td><small>{client_file_name}</small></td>
            <td>
                {/* {parent_id ?
                        "" :
                        <InputGroup size="sm" className="my-1">
                            <Input type="select" value={newPrimaryTag} onChange={(e) => {
                                setNewPrimaryTag(e.target.value);
                                onNewTag();
                            }}>
                                {Object.keys(globalTags).map((primaryTag) => (
                                    <option key={primaryTag} value={primaryTag}>
                                        {primaryTag}
                                    </option>
                                ))}
                            </Input>
                            <Input type="select" value={newSecondaryTag} onChange={(e) => {
                                setNewSecondaryTag(e.target.value);
                                onNewTag();
                            }}>
                                {Object.keys(globalTags).filter((primaryTag) => primaryTag == newPrimaryTag).map((primaryTag) => globalTags[primaryTag].map(secondaryTag => (
                                    <option key={secondaryTag} value={secondaryTag}>
                                        {secondaryTag}
                                    </option>
                                )))}
                            </Input>
                        </InputGroup>
                    } */}

                {parent_id ?
                    "" :
                    <small>
                        <CategorySelector allCategories={allCategories} selectedPairs={selectedPairs} setSelectedPairs={setSelectedPairs} className="my-1" disabled={parent_id} />
                    </small>
                }
                {parent_id ?
                    "" :
                    // <TagsInput
                    //     className={`form-control form-control-sm ${isChanged("tags") ? "border border-primary" : ""} my-1 px-1 py-1`}
                    //     style={{ padding: "0.2rem" }}
                    //     value={tags}
                    //     onChange={(value) => {
                    //         setTags(value);
                    //         setSelectedPairs(value.map(tag => {
                    //             return { category: tag.split(":")[0], subcategory: tag.split(":")[1] || null };
                    //         }));
                    //     }}
                    //     disabled
                    //     validate={(tag) => !tag.includes([","])}
                    //     inputProps={{
                    //         className: 'react-tagsinput-input',
                    //         placeholder: '',
                    //     }}
                    // />
                    <Button outline className="my-1" disabled>
                        {tags.map(tag => <Badge key={tag} className="mx-1">{tag}</Badge>)}
                    </Button>
                }
            </td>
            <td>
                <small>
                    <Input
                        type="textarea"
                        value={question}
                        onChange={e => setQuestion(e.target.value)}
                        size="sm"
                        rows="3"
                        className={isChanged("question") ? "border border-primary" : ""}
                    />
                </small>
            </td>
            <td>
                <small>
                    <Input
                        type="textarea"
                        value={answer}
                        onChange={e => setAnswer(e.target.value)}
                        size="sm"
                        rows="3"
                        className={isChanged("answer") ? "border border-primary" : ""}
                        disabled={parent_id}
                    />
                </small>
            </td>
            <td>
                <small>
                    {id ?
                        (parent_id ? "" : (
                            <>
                                <Button color="success" size="sm" onClick={() => onVariations(id)} className="mx-1 my-1">Generate</Button>
                                {/* <Button color="success" size="sm" onClick={() => onVariations(id)} className="mx-1 my-1" disabled>Add &gt;</Button> */}
                            </>
                        )) : ""}
                </small>
            </td>
            <td>
                <small>
                    {id ?
                        (approved ? (
                            <Button color="primary" size="sm" className="mx-1 my-1" disabled>Accepted</Button>
                        ) : (
                            <Button color="primary" size="sm" onClick={(e) => {
                                e.target.disabled = true;
                                onApprove(id, { question, answer, tags });
                            }} className="mx-1 my-1">Accept</Button>
                        )) : ""}
                </small>
            </td>
            <td>
                <small>
                    {id ?
                        <Button color="danger" size="sm" onClick={() => onDelete(id)} disabled title="Delete" className="mx-1 my-1"> - </Button>
                        : ""}
                </small>
            </td>
        </tr>

    );
};

export default TrainingData;
