import { BASE_API } from "config";
import store from "store";

import { changeLoader } from "store/actions";

const ROUTES_WITHOU_TOKEN = [
  "/users/login",
  "/users/forgot",
  "/users/register",
  "/users/code",
];

function isPublicRoute(url) {
  const regex1 = new RegExp("^/order/[a-f0-9]{32}$");
  const regex2 = new RegExp("^/order/[a-f0-9]{32}/payment");
  const regex3 = new RegExp("^/order/[a-f0-9]{32}/shipping");
  const regex4 = new RegExp("^/order/[a-f0-9]{32}/vault");
  const regex5 = new RegExp("^/order/[a-f0-9]{32}/addresses");

  if (
    regex1.test(url) ||
    regex2.test(url) ||
    regex3.test(url) ||
    regex4.test(url) ||
    regex5.test(url)
  )
    return true;

  if (ROUTES_WITHOU_TOKEN.includes(url)) return true;

  return false;
}

async function call(method = "GET", url, config = {}) {
  return new Promise((res, rej) => {
    try {
      const isPublic = isPublicRoute(url);

      if (!isPublic && !localStorage.getItem("authUser"))
        throw new Error("TOKEN is EMPTY");

      const authUser = JSON.parse(localStorage.getItem("authUser"));
      let token = null;
      if (authUser) token = authUser.auth.token;
      if (!isPublic && !token) throw new Error("YOU ARE NOT LOGIN");

      // const controller = new AbortController();
      // const timeoutId = setTimeout(() => controller.abort(), 50000);

      const options = {
        method: method,
        // signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'ngrok-skip-browser-warning': 'true'
        },
      };

      if (method === "POST" || method === "PUT") {
        options.body = JSON.stringify(config);
      }

      if (config instanceof FormData) {
        delete options.headers["Content-Type"];
        if (method === "POST" || method === "PUT") {
          options.body = config;
        }
      }
      // store.dispatch(changeLoader(true));
      fetch(BASE_API + url, options)
        .then(async (resp) => {
          // clearTimeout(timeoutId);

          // console.log(resp.status, method, url, config);
          if (resp.status === 401) {
            return (window.location.href = "/login");
          }

          const content_type = resp.headers.get("Content-Type");

          if (
            url.includes("/users/me/speech") &&
            content_type !== "application/json"
          ) {
            let blob = await resp.blob();
            const objectURL = URL.createObjectURL(blob);
            res(objectURL);
            return blob;
          }
          return resp.json();
        })
        .then((r) => {
          if (r.error) {
            console.log("fetch error", r);
            rej(r.error);
            return;
          }
          res(r);
        })
        .catch((e) => {
          console.log("ERROR IN FETCH CALL", e);
          rej(e);
        })
        .finally(() => {
          if (store.getState()?.Loader?.loader) {
            store.dispatch(changeLoader(false));
          }
        });
    } catch (error) {
      console.log("ERROR IN CALL", error);
      rej(error);
    }
  });
}

export const login = (cr) => call("POST", "/users/login", cr);
export const register = (cr) => call("POST", "/users/register", cr);
export const confirmCreatePassword_API = (cr) =>
  call("POST", "/users/code", cr);
export const initForgotPassword = (cr) => call("POST", "/users/forgot", cr);
export const getCurrentUser = () => call("GET", "/users/me");
export const modifyCurrentUser = (cr) => call("PUT", `/users/me`, cr);
export const getSettingsCurrentUser = (cr) =>
  call("GET", `/users/me/settings`, cr);
export const modifySettingsCurrentUser = (cr) =>
  call("PUT", `/users/me/settings`, cr);
// ====== for admin role ONLY =================
export const createUser = (cr) => call("POST", `/users`, cr);
export const getUsers = () => call("GET", `/users`);
export const getUserById = (userId) => call("GET", `/users/${userId}`);

export const getSettingsUserById = (userId) =>
  call("GET", `/users/${userId}/settings`);
export const modifyUserById = (userId, cr) =>
  call("PUT", `/users/${userId}`, cr);
export const modifySettingsUserById = (userId, cr) =>
  call("PUT", `/users/${userId}/settings`, cr);

export const deleteUserById = (userId, cr) =>
  call("DELETE", `/users/${userId}`, cr);
export const phonesAvailable = (request) =>
  call("POST", "/phones/available", request);
export const addPhones = (phone) => call("POST", "/phones/add", phone);
// ==========================================
export const getCustomersContact = (search) =>
  // call("GET", `/contacts?limit=10${offset ? `&offset=${offset}` : ""}`);
  call("GET", `/contacts${search ? `?search=${search}` : ""}`);
export const getShopifyContacts = (search) =>
  // call("GET", `/contacts?limit=10${offset ? `&offset=${offset}` : ""}`);
  call("GET", `/shop/customers${search ? `?search=${search}` : ""}`);

export const addNewCustomerContact = (cr) => call("POST", `/contacts`, cr);
export const getCustomerContactProfile = (contactId) =>
  call("GET", `/contacts/${contactId}`);
export const getNotificationsOrderByPhone = (customerID, filter) =>
  call(
    "GET",
    `/orders/${customerID}?${filter.orderId ? `id=${filter.orderId}&` : ""
    }limit=${filter.limit || 10}&offset=${filter.offset}${filter.event ? `&event=${filter.event}` : ""
    }`
  ); // order notifications

export const updateCustomerContact = (contactId, cr) =>
  call("PUT", `/contacts/${contactId}`, cr);
export const deleteCustomerContact = (contactId) =>
  call("DELETE", `/contacts/${contactId}`);
// ============================================
export const uploadFile = (file) => call("POST", "/users/upload", file);

export const getChatToken = () => call("GET", "/chat/token");
export const getVoiceToken = () => call("GET", "/voice/token");

// ================= billing ==================================
export const getBillingBalance = () => call("GET", "/billing/balance");
export const getBillingUsage = (from = "", to = "") =>
  call("GET", `/billing/usage?from=${from}&to=${to}`);
export const getBillingList = () => call("GET", `/billing`);
export const getBillingById = (invoiceId) =>
  call("GET", `/billing/${invoiceId}`);
// ================= flows ====================================
export const createFlow = (cr) => call("POST", "/flows", cr);
export const getFlows = () => call("GET", "/flows");
export const editFlow = (id, cr) => call("PUT", `/flows/${id}`, cr);
export const getFlowByID = (id) => call("GET", `/flows/${id}`);
export const deleteFlow = (id) => call("DELETE", `/flows/${id}`);
// ================= campaings ====================================
export const createCampaing = (cr) => call("POST", "/campaigns", cr);
export const getCampaings = () => call("GET", "/campaigns");
export const editCampaing = (id, cr) => call("PUT", `/campaigns/${id}`, cr);
export const getCampaingByID = (id) => call("GET", `/campaigns/${id}`);
export const deleteCampaing = (id) => call("DELETE", `/campaigns/${id}`);
export const startCampaingID = (id) => call("POST", `/campaigns/${id}`);
// ================= a2p_profile ====================================
export const createMyCompany = (cr) => call("POST", "/companies/my", cr);
export const getAllCompanies = () => call("GET", "/companies"); // for super admin only
export const editMyCompany = (cr, id) => call("PUT", `/companies/${id}`, cr); // id - for super admin only
export const getCompany = (id) => call("GET", `/companies/${id}`);

// ===================== handlers for shopify ==================
export const hashCreate = (cr) => call("POST", "/hashes", cr);
export const getShopProducts = () => call("GET", "/shop/products");
export const postOrderByContactId = (contactId, cr) =>
  call("POST", `/shop/orders/${contactId}`, cr);

// ==================== customer order create page ==========================
export const getOrderByHash = (orderHash) => call("GET", `/order/${orderHash}`);
export const updateOrderByHash = (orderHash, cr) =>
  call("PUT", `/order/${orderHash}`, cr);
export const getAddressesList = (orderHash) =>
  call("GET", `/order/${orderHash}/addresses`);
export const getVaultList = (orderHash) =>
  call("GET", `/order/${orderHash}/vault`);
export const getShippingRates = (orderHash) =>
  call("GET", `/order/${orderHash}/shipping`);
export const getSettingsBrand = (orderHash) =>
  call("GET", `/order/${orderHash}/settings`);
export const payOrderByHash = (orderHash) =>
  call("GET", `/order/${orderHash}/payment`);
export const addNotesForContact = (contactId, body) =>
  call("POST", `/contacts/${contactId}/note`, body);
// ====================== MY AI ===============================
export const voiceCreate = (cr) => call("POST", "/users/me/voice", cr); // send recorded audio file with user voice
export const voiceRead = (cr) => call("POST", "/users/me/speech", cr); // send text what you want to read
export const getUserRecordings = (id) => call("GET", `/users/${id}/recordings`); // get voice mail recordings
export const setUserRecordListened = (id, recordSID) =>
  call("PUT", `/users/${id}/recordings/${recordSID}`); // set voice mail is listened
export const createTestVoiceMessage = (body) => call("POST", `/twiml`, body);
export const createVoiceMailCampaign = (id, body) =>
  call("POST", `/users/${id}/voicecampaign`, body);
export const getVoiceMailCampaigns = () => call("GET", `/voicemailcampaigns`);
export const getVoiceMailCampaignById = (id) =>
  call("GET", `/voicemailcampaigns/${id}`);

// ================ OAI ================
// export const getSuggestion = (data) => call("POST", "/o/suggestion", data);
// export const getCompanyFiles = () => call("GET", "/o/files");
// export const uploadCompanyFile = (file) => call("POST", "/o/files", file);
// export const deleteCompanyFile = (fileId) => call("DELETE", `/o/files/${fileId}`);
// export const search = (data) => call("POST", `/o/search`, data);

export const search = (data) => call("POST", `/o/search`, data);
export const getSuggestion = (data) => call("POST", "/o/suggestion", data);
export const getSuggestions = (data) => call("POST", "/o/suggestions", data);

export const getTrainingFiles = () => call("GET", "/o/training/files");
export const uploadTrainingFile = (file) => call("POST", "/o/training/files", file);
export const deleteTrainingFile = (fileId) => call("DELETE", `/o/training/files/${fileId}`);

export const getTrainingDataTagList = () => call("GET", "/o/training/tags");
export const getTrainingDataList = (filters) => call("GET", `/o/training/data?${filters && filters.tags ? `tags=${filters.tags}` : ''}`);
export const createTrainingData = (data) => call("POST", `/o/training/data`, data);
export const updateTrainingData = (dataId, data) => call("PUT", `/o/training/data/${dataId}`, data);
export const deleteTrainingData = (dataId) => call("DELETE", `/o/training/data/${dataId}`);
export const generateVariations = (dataId) => call("GET", `/o/training/data/${dataId}/variations`);