import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledAlert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { SketchPicker } from "react-color";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import USA_states from "assets/data/USA_states";
import {
  getTrainingFiles,
  uploadTrainingFile,
  deleteTrainingFile,
  search,
} from "helpers/API_hendlers";

const USA_states_abbr = USA_states.map((stat) => ({
  label: stat.key,
  value: stat.value,
}));

import { modifyCurrentUser, changeLoader } from "../../store/actions";

const Documents = (props) => {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector(
    (state) => ({
      user: state.Login?.user,
      loading: state.Login.loading,
      error: state.Login?.error,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    showAlert: {
      init: false,
      isShow: false,
      message: "",
      type: "success",
      icon: "mdi-information-outline",
    },
    localLoading: false,
    search: null,
    answers: [],
    trainingFiles: [],
    trainingFile: null,
  });

  useEffect(() => {
    getTrainingFiles()
      .then((r) => {
        console.log(r);
        setState((pS) => ({
          ...pS,
          trainingFiles: r.list || [],
        }));
      })
      .catch((e) => console.log(e));

  }, []);

  useEffect(() => {
    // console.log("loading", loading);
    if (!loading && state.showAlert.init) {
      if (error) {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Something went wrong",
            type: "danger",
            icon: "mdi-block-helper",
            init: true,
          },
        }));
      } else {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Updated successfully",
            type: "success",
            icon: "mdi-information-outline",
            init: true,
          },
        }));
      }
    } else {
      setState((pS) => ({
        ...pS,
        showAlert: {
          isShow: false,
          message: "Something went wrong",
          type: "danger",
          icon: "mdi-block-helper",
          init: true,
        },
      }));
    }
  }, [loading]);

  const onTrainingFileUpload = async (e) => {
    const fileInput = document.getElementById('trainingFile');
    const formData = new FormData();
    formData.append("trainingFile", state.trainingFile, state.trainingFile.client_file_name);

    await uploadTrainingFile(formData)
      .then(r => {
        // console.log(r);
        if (r) {
          fileInput.value = null;
          setState((pS) => ({
            ...pS,
            trainingFile: null,
            trainingFiles: r.list || [],
          }));
        }
      });
  };

  const onSearch = async (e) => {
    const s = document.getElementById('search');

    await search({ search: state.search })
      .then(r => {
        // console.log(r);
        if (r) {
          s.value = "";
          setState((pS) => ({
            ...pS,
            answers: r.answers,
            search: "",
          }));
        }
      });
  };

  const onTrainingFileDelete = async (fileId) => {
    await deleteTrainingFile(fileId)
      .then(r => {
        // console.log(r);
        if (r) {
          setState((pS) => ({
            ...pS,
            trainingFiles: state.trainingFiles.filter(doc => doc.id !== fileId),
          }));
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Documents"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {state.showAlert.isShow && (
            <UncontrolledAlert
              style={{ zIndex: 10000 }}
              isOpen={state.showAlert.isShow}
              color={state.showAlert.type}
              className="alert-dismissible fade show position-fixed top-0 start-50"
              role="alert"
              onClick={() => {
                setState((pS) => ({
                  ...pS,
                  showAlert: {
                    ...state.showAlert,
                    isShow: false,
                  },
                }));
              }}
            >
              <i className={`mdi ${state.showAlert.icon} me-2`}></i>
              {state.showAlert.message}
            </UncontrolledAlert>
          )}
          <h4 className="card-title">Documents</h4>
          <p className="">
            Documents in the company file storage for salesreps
          </p>

          <Card>
            <CardBody>
                <Row>
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    lg={1}
                    xl={1}
                    xxl={1}
                  >
                    <Label className="mx-1 my-1">Add Document</Label>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={8}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <Input
                      id="trainingFile"
                      type="file"
                      className="mx-1 my-1"
                      size="sm"
                      onChange={e => setState((pS) => ({
                        ...pS,
                        trainingFile: e.target.files[0],
                      }))}
                      accept=".csv"
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    lg={1}
                    xl={1}
                    xxl={1}
                  >
                    {state.localLoading ? (
                      <Spinner className="mx-1 my-1" color="light" />
                    ) : (
                      <Button type="submit" size="sm" color="primary" disabled={!state.trainingFile} className="mx-1 my-1"
                      onClick={onTrainingFileUpload}>
                        Upload
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-2">
              <Table striped responsive hover>
                <thead>
                  <th>#</th>
                  <th>Created</th>
                  <th>Name of Document</th>
                  <th>Primary Data</th>
                  <th>Derivative Data</th>
                  <th>Total Data</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {state.trainingFiles.map((tf, index) => (
                    <tr key={tf.id}>
                      <td>{index + 1}</td>
                      <td>{tf.created}</td>
                      <td>{tf.client_file_name}</td>
                      <td>{tf.data_count - tf.variation_count}</td>
                      <td>{tf.variation_count}</td>
                      <td>{tf.data_count}</td>
                      <td>
                        <Button size="sm" color="danger" onClick={() => onTrainingFileDelete(data.id)} disabled>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
                  </Row>
            </CardBody>
          </Card>

          <h4 className="card-title">Sandbox</h4>
          <p className="">
            Searching in the FAQ data
          </p>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSearch();

                  return false;
                }}
              >
                <Row>
                  <Col
                    xs={12}
                    sm={8}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <Input
                      id="search"
                      type="text"
                      className="mx-1 my-1"
                      size="lg"
                      onChange={e => setState((pS) => ({
                        ...pS,
                        search: e.target.value,
                      }))}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                  >
                    {state.localLoading ? (
                      <Spinner className="mx-1 my-1" color="light" />
                    ) : (
                      <Button type="submit" size="lg" color="primary" className="mx-1 my-1">
                        Search
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    {/* <Label className="mx-1 my-1">Documents</Label> */}
                    <ListGroup>
                      {state.answers && state.answers.length ? state.answers.map((answer) => (
                        <ListGroupItem key={answer.id}>
                          <Row>
                          <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            ><span>ID: {answer.id}</span></Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            ><span>Score: {answer.score}</span></Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            ><span><strong>Question: </strong> {answer.metadata.Question || answer.metadata.question}</span></Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            ><span><strong>Answer: </strong> {answer.metadata.Answer || answer.metadata.answer}</span></Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            ><span><strong>File: </strong> {answer.metadata.filename || answer.metadata.client_file_name}</span></Col>
                          </Row>
                        </ListGroupItem>
                      )) : ''}
                    </ListGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Documents);
