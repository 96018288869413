import React, { useState } from "react";
import { Container, Row, Col, Input, ListGroup, Button } from "reactstrap";

const CategorySelector = ({ allCategories, selectedPairs, setSelectedPairs, parentId }) => {
  const [searchTerms, setSearchTerms] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [subSearchTerms, setSubSearchTerms] = useState({});
  const [subSearchResults, setSubSearchResults] = useState({});

  const categories = Object.keys(allCategories);

  const handleSearch = (term, index) => {
    setSearchResults((prev) => ({
      ...prev,
      [index]: term ? categories.filter((cat) => cat.toLowerCase().includes(term.toLowerCase())) : []
    }));
  };

  const handleSubSearch = (term, category, index) => {
    setSubSearchResults((prev) => ({
      ...prev,
      [index]: term && category ? allCategories[category]?.filter((sub) => sub.toLowerCase().includes(term.toLowerCase())) || [] : []
    }));
  };

  const updatePair = (index, key, value) => {
    const updatedPairs = [...selectedPairs];
    updatedPairs[index][key] = value;
    setSelectedPairs(updatedPairs);
  };

  const deletePair = (index) => {
    setSelectedPairs(selectedPairs.filter((_, i) => i !== index));
  };

  return (
    parentId ? "" : (
      <div className="my-1">
        {selectedPairs.map((pair, index) => (
          <ListGroup horizontal key={index} className="my-1">
            <div className="ms-0">
              <Input
                size="sm"
                type="text"
                placeholder="Category"
                value={pair.category || searchTerms[index] || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerms((prev) => ({ ...prev, [index]: value }));
                  handleSearch(value, index);
                  updatePair(index, "category", value);
                }}
              />
              {searchResults[index]?.length > 0 && (
                <ListGroup>
                  {searchResults[index].map((cat) => (
                    <Button key={cat} size="sm" onClick={() => {
                      updatePair(index, "category", cat);
                      setSearchTerms((prev) => ({ ...prev, [index]: cat }));
                      setSearchResults((prev) => ({ ...prev, [index]: [] }));
                    }}>{cat}</Button>
                  ))}
                </ListGroup>
              )}
            </div>
            <div className="ms-1">
              <Input
                size="sm"
                type="text"
                placeholder="Subcategory"
                value={pair.subcategory || subSearchTerms[index] || ""}
                disabled={!pair.category}
                onChange={(e) => {
                  const value = e.target.value;
                  setSubSearchTerms((prev) => ({ ...prev, [index]: value }));
                  handleSubSearch(value, pair.category, index);
                  updatePair(index, "subcategory", value);
                }}
              />
              {subSearchResults[index]?.length > 0 && (
                <ListGroup>
                  {subSearchResults[index].map((sub) => (
                    <Button key={sub} size="sm" onClick={() => {
                      updatePair(index, "subcategory", sub);
                      setSubSearchTerms((prev) => ({ ...prev, [index]: sub }));
                      setSubSearchResults((prev) => ({ ...prev, [index]: [] }));
                    }}>{sub}</Button>
                  ))}
                </ListGroup>
              )}
            </div>
            <Button outline color="danger" size="sm" onClick={() => deletePair(index)} className="ms-1"> - </Button>
          </ListGroup>
        ))}
        <ListGroup horizontal>
          <Button outline onClick={() => setSelectedPairs([...selectedPairs, { category: "", subcategory: "" }])} size="sm" className="w-100" color="success"> + </Button>
        </ListGroup>
      </div>
    )
  );
};

export default CategorySelector;
