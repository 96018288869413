import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, Spinner } from "reactstrap";
import store from "store";
import { statusMessageColor } from "helpers/utility";
import LinkPreviewComponent from "components/Common/LinkPreview";

import { updateState, updateVoiceMailStatus } from "store/actions";

let countResent = 5;

const MessageText = ({ text }) => {
  if (
    (text?.includes("https://") || text?.includes("http://")) &&
    !text?.includes(".mp3")
  ) {
    let modText = text;

    if (text.includes("hashCoolCloud=")) {
      modText = text.slice(0, -23);
    }
    return <LinkPreviewComponent link={modText} />;
  } else {
    if (text?.includes("https://") && text?.includes(".mp3")) {
      return;
    }
    return <p className="message_text">{text}</p>;
  }
};

const SuggestionText = ({ question, answer }) => {
  const [answerText, setAnswerText] = useState(answer);

  return (
    <p className="message_text">
      <span style={{ padding: "4px" }}>{question}</span>
      <br />
      <span>
        <Input
          type="textarea"
          size="2"
          style={{
            border: "1px",
            padding: "4px",
            width: "100%",
          }}
          value={answerText}
          onChange={(e) => {
            setAnswerText(e.target.value);
          }}
        />
      </span>
    </p>
  );
};

const MessageMedia = ({ message, user }) => {
  // console.log("message", message);
  if (
    message.media ||
    (message.attributes && message.attributes.type === "media") ||
    (message.message?.includes("https://") && message.message?.includes(".mp3"))
  ) {
    if (
      message.contentType === "text/plain" ||
      message.contentType === "application/pdf" ||
      message.contentType?.includes("application") ||
      message.contentType === "text/vcard"
    ) {
      let icon_class = "mdi-file-image-outline";

      if (message.contentType === "text/vcard") {
        icon_class = "mdi-card-account-phone";
      }
      if (message.contentType === "application/pdf") {
        icon_class = "mdi-file-pdf-box";
      }

      return (
        <>
          <p
            className="chat-time mb-0"
            style={{ fontSize: 70 }}
            onClick={() => {
              window.location.href = message.media;
            }}
          >
            <i className={`mdi ${icon_class} me-1`} id="Imagetooltip"></i>
          </p>
          <p>{message.filename}</p>
        </>
      );
    }

    if (message.contentType?.includes("video")) {
      return (
        <>
          <video controls width={300}>
            <source src={message.media} type="video/webm" />
            <source src={message.media} type="video/mp4" />
            <source src={message.media} type="video/3gpp" />
            <source src={message.media} type="video/3gp" />
            Sorry, your browser doesn't support videos.
          </video>
          <p
            onClick={() => {
              window.location.href = message.media;
            }}
          >
            {message.filename}
          </p>
        </>
      );
    }

    if (message.contentType?.includes("image")) {
      return (
        <>
          <img
            width={300}
            src={message.media}
            className="img-thumbnail"
            alt=""
          />
          <p>{message.filename}</p>
        </>
      );
    }

    if (
      (message.attributes?.type === "media" && message.attributes?.url) ||
      (message.message?.includes("https://") &&
        message.message?.includes(".mp3"))
    ) {
      const url = message.attributes?.url || message.message;

      return (
        <>
          <audio
            controls
            width={300}
            src={url}
            onEnded={(e) => {
              const startIndex =
                url.indexOf("Recordings/") + "Recordings/".length;
              const endIndex = url.indexOf(".mp3");

              let recordingSid = "";

              if (startIndex !== -1 && endIndex !== -1) {
                recordingSid = url.substring(startIndex, endIndex);
              } else {
                console.log("Substring not found");
              }
              store.dispatch(updateVoiceMailStatus(user.id, recordingSid));
            }}
          />
        </>
      );
    }

    return <></>;
  } else {
    return <></>;
  }
};

const handleResentMessage = async (message) => {
  // console.log(message);

  let MEDIA = null;

  if (message.media) {
    const file = await fetch(message.media);
    // console.log("file", file);
    const fileBlob = await file.blob();
    // console.log("fileBlob", fileBlob);
    MEDIA = fileBlob;
  }
  const curMessage = {
    text: message.message,
    media: MEDIA,
    attributes: {},
    selectedFile: { type: message.contentType, name: message.filename },
  };

  // console.log(curMessage);

  await ClientConversation.sendMessage(curMessage)
    .then((messageIndexN) => {
      // console.log("messageIndex", messageIndexN);
      message.FULL_DATA_MESSAGE.remove().then((r) => {
        // console.log("deleted", r);
        store.dispatch(
          updateState({
            messages: {
              removeByIndex: message.index,
            },
          })
        );
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

const MessageStatusTime = ({ message, user }) => {
  const status = statusMessageColor(message);
  const isMessageLast = ClientConversation.isLastMessage(message.index);

  useEffect(() => {
    if (
      status.statusInfo === "undelivered" &&
      isMessageLast &&
      countResent > 0
    ) {
      handleResentMessage(message);
      countResent = countResent - 1;
    }
    if (status.statusInfo === "delivered") {
      countResent = 5;
    }
  }, [status.statusInfo]);

  return (
    <>
      <p className="chat-time mb-0" title={status?.message}>
        <i className={`bx bx-time-five me-1  ${status?.color || ""}`}></i>{" "}
        {message.time}
        {"  "}
        {message.email === user.email && (
          <>
            {!status || status.message === "wait" ? (
              <Spinner tag="span" className="" color="primary" size="sm" />
            ) : (
              <>
                <i
                  id={`status_message_${message?.id}`}
                  className={`mdi mdi-alert-box me-1 ${status?.color || ""}`}
                  style={{ marginLeft: 5 }}
                ></i>

                {status.statusInfo === "undelivered" &&
                  isMessageLast &&
                  countResent === 0 && (
                    <Button
                      outline
                      size="sm"
                      // type="button"
                      color="info"
                      onClick={() => {
                        handleResentMessage(message);
                        countResent = 5;
                      }}
                    >
                      Resent
                    </Button>
                  )}
              </>
            )}
          </>
        )}
      </p>
    </>
  );
};

const SuggestionStatus = ({ timestamp, time, onSend, id, text }) => {

  return (
    <>
      <p className="chat-time mb-0">
        <small>
          <i className={`bx bx-time-five me-1`}></i>{" "}
          {time}
          {"  "}
          <span style={{ float: "right" }}>
            <Button size="sm" title="Accept and Send" onClick={e => onSend(id, text)}>
              <i className="mdi mdi-send" />
            </Button>
          </span>
        </small>
      </p>
    </>
  );
};

function MessageItem({ message, user, onSend }) {
  // console.log("message", message);

  let mesSender = message.sender;
  const isSuggestion = message.email == "ai@ai";

  if (message.email === user.email) {
    mesSender = user.first_name + " " + user.last_name;
  }
  if (message.attributes?.campaignId) {
    mesSender = "Campaign message";
  }

  return isSuggestion ? (
    <li
      key={"test_k_" + message.id}
    >
      <div className="conversation-list"
        style={{
          display: "block",
          maxWidth: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          boxShadow: "0px 0px 10px 0px rgba(40, 51, 172, 0.75)",
        }}
      >
        <div className="ctext-wrap" style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
          <div className="conversation-name">
            <span>{`${mesSender} ${user.first_name} ${user.last_name}`}</span>
            <span style={{ float: "right", color: "dark-red" }}><small>{message.classification}</small></span>
          </div>
          <SuggestionText question={message?.metadata?.question} answer={message?.metadata?.answer} />
          <SuggestionStatus timestamp={message.timestamp} time={message.time} onSend={onSend} id={message?.id} text={message?.metadata?.answer} />
        </div>
      </div>
    </li>
  ) : (
    <li
      key={"test_k_" + message.id}
      className={message.email === user.email ? "right" : ""}
    >
      <div className="conversation-list">
        <div className="ctext-wrap">
          <div className="conversation-name">{mesSender}</div>
          <MessageMedia message={message} user={user} />
          <MessageText text={message?.message} />
          <MessageStatusTime message={message} user={user} />
        </div>
      </div>
    </li>
  );
}

export default React.memo(MessageItem);
