import { uploadFile } from "./API_hendlers";
import moment from "moment";
import store from "store";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export async function loadImage(imageData) {
  return new Promise(async (res) => {
    try {
      fetch(imageData)
        .then((response) => response.blob())
        .then((blob) => {
          // console.log("loadImage", blob);
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            const base64data = reader.result;
            const cutUnuse = base64data.slice(base64data.indexOf(",") + 1);
            res(`PHOTO;ENCODING=BASE64;TYPE=JPEG:${cutUnuse}`);
          };
          reader.onerror = function () {
            console.log(reader.error);
            res(null);
          };
        });
    } catch (error) {
      console.log("loadImage", error);
    }
  });
}

export async function createImageLink(selectedFile) {
  return new Promise((res, rej) => {
    try {
      if (!selectedFile) return res(null);

      const formData = new FormData();
      formData.append("files", selectedFile, selectedFile?.name);
      uploadFile(formData)
        .then((r) => res(r))
        .catch((e) => res(null));
    } catch (error) {
      console.log(error);
      res(null);
    }
  });
}

export function isUseThisNumber(phone) {
  try {
    // console.log("conversations", conversations);
    // console.log("phone", phone);

    const conversations = store.getState().chat.conversations;

    const phoneInputValue = phone.replace(/\D/g, "");

    let convToUse_SID = null;

    for (const conv of conversations) {
      const participants = Object.fromEntries(conv._participants);
      for (const partSID in participants) {
        if (
          participants[partSID].state?.type === "sms" &&
          participants[partSID].state?.bindings?.sms?.address.replace(
            /\D/g,
            ""
          ) === phoneInputValue
        ) {
          convToUse_SID = conv.sid;
        }
      }
    }
    if (!convToUse_SID) return;

    return convToUse_SID;
    // console.log("convToUse_SID", convToUse_SID);
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function createVcard(user, imageData) {
  try {
    const vcard_type = "text/vcard";

    let PHOTO = `PHOTO;ENCODING=BASE64;TYPE=JPEG:${imageData}`;

    if (imageData?.includes("https://api.coolcloud.com/storage")) {
      const base64_from_url_image = await loadImage(imageData);
      if (base64_from_url_image) {
        PHOTO = base64_from_url_image;
      }
    }
    if (!imageData) {
      PHOTO = null;
    }
    //Post Office Address; Extended Address; Street; Locality; Region; Postal Code; Country)
    const o = {
      PObox: "",
      StrAddr: user?.address || "",
      StrAddr2: user?.address_2 || "",
      Locality: user?.city || "",
      Region: user?.state || "",
      zip: user?.zip || "",
      country: "United States",
    };

    const address = `ADR;TYPE=WORK:${o.PObox};${o.StrAddr};${o.StrAddr2};${o.Locality};${o.Region};${o.zip};${o.country}\n`;

    const V = `VERSION:3.0\n`;
    const N = `N:${user?.last_name || "last name"};${
      user?.first_name || "first name"
    }\n`;
    const FN = `FN:${user?.first_name || "Rep"}\n`;
    const ORG = user?.company_title ? `ORG:${user?.company_title}\n` : "";
    const TEL = user?.phone ? `TEL;TYPE=WORK,VOICE:${user?.phone}\n` : "";
    const EMAIL = user?.email ? `EMAIL:${user?.email}\n` : "";
    const REV = `REV:${new Date().toISOString()}\n`;
    const PH = PHOTO ? `${PHOTO}\n` : "";

    let vcard = `BEGIN:VCARD\n${V}${N}${FN}${ORG}${address}${TEL}${EMAIL}${REV}${PH}END:VCARD`;
    // console.log(vcard);

    const file_vcard = new Blob([vcard], { type: vcard_type });

    return {
      file_vcard,
      vcard_type,
      file_name: `${user?.first_name}_${user?.last_name}.vcf`,
    };
  } catch (error) {
    console.log(error);
  }
}
let intervalID = null;
export function updateTimeInNotifications(action) {
  if (action === "start") {
    intervalID = setInterval(update, 60000);
  }

  if (action === "stop") {
    clearInterval(intervalID);
  }

  function update() {
    // console.log("Update time in notifications");

    const locNot = localStorage.getItem("notifications");
    if (locNot) {
      const notifications = JSON.parse(locNot);

      const newNots = notifications.map((not) => ({
        ...not,
        timeFromNow: moment(not.time).fromNow(),
      }));

      localStorage.setItem("notifications", JSON.stringify(newNots));
    }
  }
}

export function removeOpenedConvFromNotific(convSID) {
  const fullNotifFromLocalStorage = localStorage.getItem("notifications");
  if (fullNotifFromLocalStorage) {
    const nots = JSON.parse(fullNotifFromLocalStorage);
    let newNots = [];
    newNots = nots.filter((not) => not.convSID !== convSID);
    localStorage.setItem("notifications", JSON.stringify(newNots));
  }
}

export function isNotifBySID(convSID) {
  const fullNotifFromLocalStorage = localStorage.getItem("notifications");
  if (fullNotifFromLocalStorage) {
    const nots = JSON.parse(fullNotifFromLocalStorage);
    return nots.find((not) => not.convSID === convSID);
  }
}

export async function custom_delay(delay) {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, delay);
  });
}

export function statusMessageColor(message) {
  let statusColor = "text-info";
  let messageStatus = "wait";

  const delRec = message.detailedDeliveryReceipts ? message.detailedDeliveryReceipts[0] : "";
  // console.log("delRec", delRec);

  const status = delRec && delRec?.status;

  if (status === "undelivered" || status === "failed") {
    statusColor = "text-danger";
    messageStatus = `Message was ${status}`;
  }

  if (!status) {
    statusColor = "text-info";
    messageStatus = ``;
  }

  if (status === "delivered") {
    statusColor = "text-success";
    messageStatus = `Message was ${status}`;
  }

  return { color: statusColor, message: messageStatus, statusInfo: status };
}

export const getMS = (value, period) => {
  switch (period) {
    case "min":
      return value * 60 * 1000;
    case "hour":
      return value * 3600 * 1000;
    case "day":
      return value * 3600 * 1000 * 24;
    default:
      return null;
  }
};

export const isDeepEqual = (object1, object2) => {
  // console.log("object1", object1);
  // console.log("object2", object2);

  if (!object1 && !object2) {
    return true;
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = typeof value1 === "object" && typeof value2 === "object";

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const searchVanilla = (
  inputSearchID,
  idContainer_ul,
  tagChild,
  tagTextContent
) => {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(inputSearchID);
  filter = input.value.toUpperCase();
  ul = document.getElementById(idContainer_ul);
  li = ul.getElementsByTagName(tagChild);
  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName(tagTextContent)[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
};

export const formatDate = (currentDate, currentTime) => {
  try {
    if (!currentDate) {
      return;
    }
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(currentDate.getUTCDate()).padStart(2, "0");
    let hours = String(currentDate.getUTCHours()).padStart(2, "0");
    let minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
    let seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");

    if (currentTime) {
      hours = String(currentTime.getUTCHours()).padStart(2, "0");
      minutes = String(currentTime.getUTCMinutes()).padStart(2, "0");
      seconds = String(currentTime.getUTCSeconds()).padStart(2, "0");
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  } catch (error) {
    console.log(error);
  }
};

export const formatDateFromUTC = (currentDate) => {
  try {
    // console.log("currentDate", currentDate);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();

    const utcDate = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds)
    );
    // console.log("utcDate", utcDate);

    return utcDate;
  } catch (error) {
    console.log(error);
  }
};

export const fetchLinkPreview = (link) => {
  return new Promise((res, rej) => {
    fetch(link)
      .then((r) => r.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const title = doc.querySelector("title")?.textContent || "";
        const description =
          doc
            .querySelector('meta[name="description"]')
            ?.getAttribute("content") || "";

        let image =
          doc
            .querySelector('meta[property="og:image"]')
            ?.getAttribute("content") || "";

        let icon =
          doc.querySelector('link[rel="icon"]')?.getAttribute("href") || "";

        // console.log("icon", icon);
        //link rel="icon"
        if (!image) {
          const imgElement = doc.querySelector("img");
          if (imgElement) {
            image = imgElement.src;
          }
        }

        if (
          link.includes(window.location.origin) &&
          localStorage.getItem("brandSettings") !== "null"
        ) {
          const brandSettings = JSON.parse(
            localStorage.getItem("brandSettings")
          );
          if (brandSettings && brandSettings.settings.brandLogo) {
            image = brandSettings.settings.brandLogo;
          }
        }

        res({ title, description, image, icon });
      })
      .catch((e) => {
        console.log(e.message);
        rej(e);
      });
  });
};

export function phoneNumberFormatter(phone) {
  if (phone) {
    let format = phone.replace(/\s/g, "").replace(/[()-]/g, "");

    if (format.includes("+") && format.indexOf("+") === 0) {
      return format.slice(1);
    }

    return format;
  }
}

export function phoneModifiyng(phone) {
  if (phone.includes("(")) {
    return phone;
  }

  let val = phone.replace(/ /gm, "");
  let num = `${val.substring(0, 2)} (${val.substring(2, 5)}) ${val.substring(
    5,
    8
  )}-${val.substring(8, val.length)}`;

  num = num.trim();
  return num;
}

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export function textGenerator(amountWords) {
  if (amountWords === 1) {
    return Math.random().toString(36).slice(2).toUpperCase();
  }
  const randomTextLength = Math.floor(Math.random() * 20);
  const testString = [];
  for (let i = 0; i < randomTextLength; i++) {
    testString.push(Math.random().toString(36).slice(2));
  }
  return testString.join(" ");
}

export function mergeArraysById(largeArray, smallArray, identificator) {
  const id = identificator ? identificator : "id";

  const largeArrayMap = new Map(
    largeArray.map((item) => [item[id], { ...item, isSelect: false }])
  );

  smallArray.forEach((smallItem) => {
    if (largeArrayMap.has(smallItem[id])) {
      largeArrayMap.set(smallItem[id], {
        ...smallItem,
      });
    } else {
      largeArrayMap.set(smallItem[id], smallItem);
    }
  });

  const resultedArray = Array.from(largeArrayMap.values());

  return resultedArray;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function differenceParse(difference) {
  if (!difference) return null;
  const info = [];
  const parsed = Object.entries(difference);
  parsed.forEach((field) => {
    if (typeof field[1] !== "object") {
      const isDate = !isNaN(new Date(field[1]));
      let value = field[1];
      if (isDate) {
        value = new Date(field[1]).toLocaleTimeString();
      }

      info.push(`${field[0]}: ${value}`);
    }
  });
  return info;
}

export function getDataMesGoogle(payloadInit, messageId) {
  let data = [];

  async function recursGetData(payload) {
    const mesPartData = {
      partId: payload.partId,
      mimeType: payload.mimeType,
      data: null,
      headers: getMessageHeaders(payload.headers),
      attachment: null,
    };

    if (
      payload.body &&
      payload.body.size &&
      (payload.body.data || payload.body.attachmentId)
    ) {
      if (payload.body.data) {
        mesPartData.data = decodeMessageBody(payload.body.data);
      }
      if (payload.body.attachmentId) {
        const attachment = await getMessageAttachments(
          messageId,
          payload.body.attachmentId
        );
        mesPartData.attachment = { filename: payload.filename, attachment };
      }
    }

    if (
      mesPartData.attachment ||
      mesPartData.data ||
      Object.keys(mesPartData.headers).length !== 0
    ) {
      data.push(mesPartData);
    }

    if (payload.parts) {
      payload.parts.forEach(recursGetData);
    }
  }

  recursGetData(payloadInit);

  return data;
}

function getMessageHeaders(payloadHeaders) {
  const headers = {};

  const neededHeaders = [
    "to",
    "from",
    "reply-to",
    "subject",
    "delivered-to",
    "return-path",
  ];

  if (
    payloadHeaders &&
    Array.isArray(payloadHeaders) &&
    payloadHeaders.length
  ) {
    payloadHeaders.forEach((header) => {
      if (neededHeaders.includes(header.name.toLowerCase())) {
        if (header.name === "Return-Path") {
          headers[header.name] = header.value.replace(/[<>]/g, "");
        } else {
          headers[header.name] = header.value;
        }
      }
    });
  }

  return headers;
}

async function getMessageAttachments(messageId, id) {
  const attachment =
    await window.gapi.client.gmail.users.messages.attachments.get({
      userId: "me",
      messageId: messageId,
      id: id,
    });

  return attachment;
}

function decodeMessageBody(data) {
  const base64 = data.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4 !== 0) {
    base64 += "=";
  }
  const binaryData = atob(base64);
  const binaryLength = binaryData.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryData.charCodeAt(i);
  }
  const decoder = new TextDecoder("utf-8");
  return decoder.decode(bytes);
}

export const base64ToBlob = (base64, mimeType) => {
  try {
    const byteCharacters = decodeMessageBody(base64);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.log("Error decoding base64 string:", error);
    return null;
  }
};

export const parseResultVoiceMail = (data) => {
  try {
    if (!data) {
      return {};
    }
    const parse = JSON.parse(data);
    return parse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const statusChat = (status) => {
  let correctStatusIcon = "text-warning";
  let isNotConnect = false;
  if (status === "Connected") {
    correctStatusIcon = "text-success";
  }
  if (status === "Connecting..." || status === "Disconnecting...") {
    correctStatusIcon = "text-warning";
  }
  if (
    status === "Coldn't get chat token" ||
    status === "Disconnected" ||
    status === "Denied. Failed to connect."
  ) {
    isNotConnect = true;
    correctStatusIcon = "text-danger";
  }
  return { correctStatusIcon, isNotConnect };
};

export const constructorEmail = async (data) => {
  const { to, from, subject, text, attachments = [] } = data;

  const boundary = "__myapp__";
  const nl = "\r\n";
  let email = [
    `MIME-Version: 1.0${nl}`,
    `Content-Type: multipart/mixed; boundary="${boundary}"${nl}`,
    `to: ${to}${nl}`,
    `from: ${from}${nl}`,
    `subject: ${subject}${nl}`,
    `${nl}--${boundary}${nl}`,
    `Content-Type: text/plain; charset="UTF-8"${nl}`,
    `Content-Transfer-Encoding: 7bit${nl}`,
    `${nl}`,
    `${text}${nl}`,
  ].join("");

  if (attachments.length > 0) {
    let allPromoses = [];

    attachments.forEach((attachment) => {
      allPromoses.push(
        new Promise(async (res, rej) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            const content = e.target.result.split(",")[1];
            const attach = [
              `${nl}--${boundary}${nl}`,
              `Content-Type: ${attachment.selectedFile.type}; name="${attachment.selectedFile.name}"${nl}`,
              `Content-Transfer-Encoding: base64${nl}`,
              `Content-Disposition: attachment; filename="${attachment.selectedFile.name}"${nl}`,
              `${nl}`,
              `${content}${nl}`,
            ].join("");
            res(attach);
          };
          reader.onerror = function () {
            rej(`error load file ${attachment.selectedFile.name}`);
          };
          reader.readAsDataURL(attachment.media);
        })
      );
    });

    await Promise.all(allPromoses)
      .then((result) => {
        result.forEach((attachment) => {
          email += attachment;
        });

        email += `${nl}--${boundary}--${nl}`;

        email = btoa(email)
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
      })
      .catch((e) => console.log(e));
  } else {
    email += `${nl}--${boundary}--${nl}`;
    email = btoa(email)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  return email;
};

export const mergeConversationsCustomers = (conversations, customers) => {
  const existedConversations = new Map(
    conversations.map((item) => [item._internalState.friendlyName, item])
  );
  customers.forEach((customer) => {
    const fN = `${customer.first_name} ${customer.last_name}`;
    if (!existedConversations.has(fN) && customer.email) {
      existedConversations.set(fN, customer);
    }
  });
  const resultedArray = Array.from(existedConversations.values());
  return resultedArray;
};
